import React, { useEffect, useRef, useState } from "react";

import Logo from "../Logo/Logo";
import blueLogo from "../../assets/blueLogo.svg";
import {
  getEventTypes,
  getGuests,
  getServices,
  getStates,
  submitEvent,
} from "../../mockApi";
import { Link } from "react-router-dom";
import EventInfo from "./EventInfo";
import SelectVendor from "./SelectVendor";
import Negotiate from "./Negotiate";
import ContactDetails from "./ContactDetails";
import Successful from "./Successful";
import "./PageLayout.css";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";

const whyQuickplanners = [
  {
    sn: 1,
    name: "Cut Negotiation Time",
    title:
      "Only connect with vendors that have seen your budget, are available at the time of your event and can deliver.",
  },
  {
    sn: 2,
    name: "Get Verified Vendors",
    title:
      "All our vendors undergo a thorough vetting and verification process, ensuring that we provide you with credible professionals.",
  },
  {
    sn: 3,
    name: "Escrow Service",
    title:
      "Escrow service to help ensure that vendors consistently deliver your job as requested.",
  },
];

const PageLayout = () => {
  const [page, setPage] = useState(0);
  const [eventData, setEventData] = useState({
    eventType: "",
    eventDate: "",
    eventDuration: "",
    state: "",
    guests: "",
    hasVenue: true,
    venueName: "",
    eventName: "",
    venueLocation: "",
    proposedVenueType: "Indoor",
    venueType: "Indoor",
    otherProposedVenueType: "",
    hasMoreInfo: false,
    additionalInfo: "",
    budget: "",
    budgetAmount: "",
    budgetEstimate: "",
    vendors: [],
    servicesData: [{ hasMoreInfo: false }],
    startDate: "",
    endDate: "",
    moreInfo: "",
    fullName: "",
    phone: "",
    email: "",
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete] = useState(false);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const stepRef = useRef([]);
  const [states, setStates] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [guests, setGuests] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [categoryError, setCategoryError] = useState([]);

  useEffect(() => {
    if (stepRef.current.length > 0) {
      setMargins({
        marginLeft: stepRef.current[0]?.offsetWidth / 2 || 0,
        marginRight:
          stepRef.current[stepRef.current.length - 1]?.offsetWidth / 2 || 0,
      });
    }

    getServices().then((data) => {
      setVendors(data);
    });

    getStates().then((data) => {
      setStates(data);
    });

    getEventTypes().then((data) => {
      setEventTypes(data);
    });

    getGuests().then((data) => {
      setGuests(data.guests);
    });
  }, []);

  const calculateProgressBarWidth = () => {
    return ((currentStep - 1) / (FormTitles.length - 1)) * 100;
  };

  const FormTitles = [
    "Event Info",
    "Select Vendor",
    "Negotiate",
    "Contact Details",
  ];

  const validateEventDetails = () => {
    const newErrors = {};
    const addError = (field, message) => {
      newErrors[field] = message;
      toast.error(message);
    };

    if (!eventData.eventType) addError("evenType", "Type of event is required");

    if (!eventData.eventName)
      addError("eventName", "Assign a name to this event is required");

    if (eventData.hasVenue && !eventData.venueName)
      addError("venueName", "Name of venue is required");

    if (eventData.hasVenue && !eventData.venueLocation)
      addError("venueLocation", "Location of venue is required");

    if (eventData.hasVenue && !eventData.venueType)
      addError("venueType", "Type of venue is required");

    if (eventData.eventDuration === "single" && !eventData.eventDate) {
      addError("eventDate", "Date of event is required");
    }
    if (eventData.eventDuration === "multiple" && !eventData.startDate) {
      addError("startDate", "Start Date of event is required");
    }
    if (eventData.eventDuration === "multiple" && !eventData.endDate) {
      addError("endDate", "End Date of event is required");
    }
    if (eventData.venueType === "Other" && !eventData.otherVenueType) {
      addError(("otherVenueType", "Venue Type is required"));
    }
    if (
      !eventData?.hasVenue &&
      eventData.proposedVenueType === "Other" &&
      !eventData.otherProposedVenueType
    ) {
      addError("otherProposedVenueType", "Venue Type is required");
    }
    if (!eventData.eventDuration) {
      addError("eventDuration", "Event Duration is required");
    }
    if (!eventData.state) {
      addError("state", "State of event is required");
    }
    if (!eventData.guests) {
      addError("guests", "Number of guests is required");
    }

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({});
    }, 10000);

    return Object.keys(newErrors).length === 0;
  };

  const validateCategoryDetails = () => {
    const newErrors = {};

    if (!eventData.vendors || eventData.vendors.length === 0)
      newErrors.category = "Please select a service";

    toast.error(newErrors.category);

    setTimeout(() => {
      setCategoryError({});
    }, 10000);

    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    setLoading(true);
    if (page === 0) {
      if (validateEventDetails()) {
        setLoading(false);
        setPage((currPage) => currPage + 1);
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else if (page === 1) {
      if (validateCategoryDetails()) {
        setLoading(false);
        setPage((currPage) => currPage + 1);
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else if (page === FormTitles.length - 1) {
      const transformedData = {
        name: eventData.eventName || "",
        event_type: eventData.eventType || "",
        duration: eventData.eventDuration || "",
        state: eventData.state || "",
        venue_type: eventData.venueType || eventData.proposedVenueType || "",
        venue_name: eventData.venueName || "",
        venue_location: eventData.venueLocation || "",
        additional_info: eventData.additionalInfo || eventData.moreInfo || "",
        have_venue: eventData.hasVenue ? "1" : "0",
        selected_date:
          eventData.eventDuration === "single" ? eventData.eventDate : "",
        start_date:
          eventData.eventDuration === "multiple"
            ? eventData.startDate || ""
            : "",
        end_date:
          eventData.eventDuration === "multiple" ? eventData.endDate || "" : "",
        services: JSON.stringify(
          eventData.servicesData.map((service) => service.name)
        ),
        contact_fullname: eventData.fullName || eventData.name || "",
        contact_email: eventData.email || "",
        contact_phone: eventData.phone || "",
        guests: eventData.guests || "",
        serviceIDs: JSON.stringify(
          eventData.servicesData.map((service) => service.id)
        ),
        amount: JSON.stringify(
          eventData.servicesData.map((service) => service.amount || "")
        ),
        is_negotiable: JSON.stringify(
          eventData.servicesData.map((service) =>
            service.isNegotiable ? "1" : "0"
          )
        ),
        max_amount: JSON.stringify(
          eventData.servicesData.map((service) =>
            service.maxAmount ? service.maxAmount : ""
          )
        ),
        min_amount: JSON.stringify(
          eventData.servicesData.map((service) =>
            service.minAmount ? service.minAmount : ""
          )
        ),
      };

      await submitEvent(transformedData);
      setLoading(false);
      setSubmitted(true);
      setPage((currPage) => currPage + 1);
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      setLoading(false);
      setPage((currPage) => currPage + 1);
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === 1) {
        return prevStep;
      } else {
        setPage((currPage) => currPage - 1);
        return prevStep - 1;
      }
    });
  };

  const PageDisplay = () => {
    if (submitted) {
      return <Successful />;
    }

    if (page === 0) {
      return (
        <EventInfo
          eventData={eventData}
          setEventData={setEventData}
          states={states}
          eventTypes={eventTypes}
          guests={guests}
          errors={errors}
        />
      );
    } else if (page === 1) {
      return (
        <SelectVendor
          eventData={eventData}
          setEventData={setEventData}
          states={states}
          vendors={vendors}
          categoryError={categoryError}
        />
      );
    } else if (page === 2) {
      return <Negotiate eventData={eventData} setEventData={setEventData} />;
    } else {
      return (
        <ContactDetails eventData={eventData} setEventData={setEventData} />
      );
    }
  };

  return (
    <div className="content">
      {/* Helmet for setting the page title */}
      <Helmet>
        <title>Connect with verified event service providers</title>
      </Helmet>
      <section className="d-flex vendor-registeration">
        <div className="side">
          <div className="logo">
            <Logo />
          </div>

          <h2 className="sub-head">Why Quickplanners?</h2>

          <div className="register-reason">
            <div>
              {whyQuickplanners.map((item) => (
                <div className="reason-item d-flex gap-3" key={item.sn}>
                  <div className="reason-icon">
                    <p className="sn">{item.sn}</p>
                  </div>
                  <div className="reason-items">
                    <p className="">{item.name}</p>
                    <span className="">{item.title}</span>
                  </div>
                </div>
              ))}{" "}
            </div>
          </div>
        </div>

        <div className="main">
          <Link to="/">
            <img src={blueLogo} alt="Logo" className="blue-logo" />
          </Link>

          <h2 className="main-head">Connect With Vendors</h2>

          <p className="sub-para">Let’s connect you with reliable vendors</p>
          <div className="stepper">
            {FormTitles.map((title, index) => (
              <div
                key={title}
                ref={(el) => (stepRef.current[index] = el)}
                className={`step ${
                  currentStep > index + 1 || isComplete ? "complete" : ""
                } ${currentStep === index + 1 ? "active" : ""}`}
              >
                <div className="step-number">
                  {currentStep > index + 1 || isComplete ? (
                    <span>&#10003;</span>
                  ) : currentStep === index + 1 ? (
                    <span>&#x3f;</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}

            <div
              className="progress-bar"
              style={{
                width: `calc(100% - ${
                  margins.marginLeft + margins.marginRight
                }px)`,
                marginLeft: margins.marginLeft,
                marginRight: margins.marginRight,
              }}
            >
              <div
                className="progress"
                style={{ width: `${calculateProgressBarWidth()}%` }}
              ></div>
            </div>
          </div>

          {/* Form details */}

          <div className="">
            <div className="progressbar">
              <div
                style={{
                  width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%",
                }}
              ></div>
            </div>
            <div className="form-container">
              <div className="body">{PageDisplay()}</div>
              <div className="footer">
                <div className="d-flex justify-content-between align-items-center stepper-nav">
                  {currentStep > 1 && !submitted && (
                    <button
                      className="step-btn btn-ghost d-flex align-items-center gap-3 complete"
                      onClick={handlePrevious}
                    >
                      Back
                    </button>
                  )}
                  <div></div>

                  {!isComplete && !submitted && (
                    <>
                      {!submitted && currentStep === FormTitles.length ? (
                        <>
                          <button
                            className={`step-btn btn-full d-flex align-items-center gap-3 ${
                              currentStep === FormTitles.length
                                ? "complete"
                                : ""
                            }`}
                            onClick={handleNext}
                          >
                            {!loading && "Submit"}{" "}
                            {loading && (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </>
                      ) : (
                        <button
                          className={`step-btn btn-full d-flex align-items-center gap-3 complete `}
                          onClick={() => handleNext()}
                        >
                          {" "}
                          Next{" "}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </div>
  );
};

export default PageLayout;
