import { useEffect, useState } from "react";
import ButtonNav from "../../Button/ButtonNav";
import "./Form.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { newsLetterSignUp } from "../../../mockApi";

export default function Form() {
  const [formData, setFormData] = useState({ fname: "", email: "", phone: "" });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [messageHead, setMessageHead] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [loading, setLoading] = useState(false);

  const publicKey = "6Letah8qAAAAAHzPZ3M-R8I_4vKoh9wKmCAG4wpq";

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${publicKey}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };
    loadRecaptchaScript();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({ fname: "", email: "", phone: "" });
    setIsSubmitted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.fname || !formData.email || !formData.phone) {
      toast.error("Oops! All fields are required");
      return;
    }

    setLoading(true);
    try {
      const token = await executeRecaptcha("submit");

      const updatedFormData = {
        email: formData.email,
        name: formData.fname,
        phone: formData.phone,
        g_recaptcha_response: token,
      };

      const response = await newsLetterSignUp(updatedFormData);
      console.log(response);
      handleResponse(response);
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const executeRecaptcha = (action) => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha && window.grecaptcha.ready) {
        window.grecaptcha.ready(async () => {
          try {
            const token = await window.grecaptcha.execute(publicKey, {
              action,
            });
            resolve(token);
          } catch (error) {
            reject(error);
          }
        });
      } else {
        reject(new Error("reCAPTCHA not loaded"));
      }
    });
  };

  const handleResponse = (response) => {
    switch (response.message) {
      case "Already subscribed successfully":
        setMessageHead("You've already signed up.");
        setMessageBody("Thank you");
        setIsSubmitted(true);
        break;
      case "Subscribed successfully":
        setMessageHead("Thank you for signing up!");
        setMessageBody("We'll notify you when we launch.");
        setIsSubmitted(true);
        break;
      default:
        toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="form-component">
      {!isSubmitted ? (
        <form className="form d-flex flex-column" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            className="form-input"
            name="fname"
            value={formData.fname}
            onChange={handleChange}
            required
            aria-label="Full Name"
          />
          <input
            type="tel"
            placeholder="Phone Number"
            className="form-input"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            aria-label="Phone Number"
          />
          <input
            type="email"
            placeholder="Email Address"
            className="form-input"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            aria-label="Email Address"
          />

          <ButtonNav type="submit" disabled={loading}>
            {!loading ? (
              "Notify me"
            ) : (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </ButtonNav>
        </form>
      ) : (
        <div className="success-message">
          <div className="message-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-check-circle"
            >
              <path d="M9 11l3 3L22 4"></path>
              <circle cx="12" cy="12" r="10"></circle>
            </svg>
          </div>

          <h3>{messageHead}</h3>
          <p>{messageBody}</p>
          <button className="close-button" onClick={resetForm}>
            Close
          </button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
