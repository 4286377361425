import success from "../../assets/reg success.svg";

export default function SignUpSuccessful() {
  return (
    <div className="d-flex flex-column align-items-center">
      <img
        src={success}
        alt="Registration successful icon"
        className="reg-success-icon"
      />
      <h1 className="reg-success-heading">Registration Successful</h1>
      <p className="reg-success-para text-center">
        Your form has been successfully submitted. We will review your
        application and notify you if your vendor status is approved.
      </p>
      <p className="reg-success-para text-center">
        You'll hear from us soon. Cheers!
      </p>
    </div>
  );
}
