import { Link } from "react-router-dom";
import betaLogo from "../../assets/Quickplanners Beta.png";
import "./Logo.css";

export default function Logo() {
  return (
    <Link to="/">
      <img src={betaLogo} alt="Quick Planners Logo" className="logo" />
    </Link>
  );
}
