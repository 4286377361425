import Social from "../Social Icon/Social";
import facebook from "../../assets/Facebook.svg";
import instagram from "../../assets/Instagram.svg";
import whatsapp from "../../assets/whatsapp-social-media-svgrepo-com.svg";
import "./Footer.css";
import { useState } from "react";
import Toast from "../Toast/Toast";

export default function Footer() {
  const [toasts, setToasts] = useState([]);

  const handleClick = () => {
    const newToast = {
      id: Date.now(),
      header: "Quickplanners",
      body: "Sorry, this feature is coming soon!",
    };
    setToasts([...toasts, newToast]);
  };

  const handleRemoveToast = (id) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  return (
    <footer className="footer d-flex justify-content-between ">
      <div className="social-icons d-flex align-items-center">
        <a href="https://www.facebook.com/quickplannersapp" target="blank">
          <Social img={facebook} />
        </a>
        <a href="https://www.instagram.com/quickplanners" target="blank">
          <Social img={instagram} />
        </a>

        <a href="https://wa.link/qnt5ga" target="blank">
          <Social img={whatsapp} />
        </a>
      </div>

      <div className="footer-para d-flex align-items-center">
        <p onClick={handleClick}>FAQ</p>
        <p onClick={handleClick}>Privacy Policy</p>
      </div>

      <Toast toasts={toasts} removeToast={handleRemoveToast} />
    </footer>
  );
}
