import React from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet for setting the title
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import "./soon.css";

export default function Soon() {
  return (
    <div className="content">
      {/* Set the page title using Helmet */}
      <Helmet>
        <title>Connect with verified event service providers</title>
      </Helmet>

      <div className="rectangle"></div>
      <Nav />
      <Header />
      <Footer />
    </div>
  );
}
