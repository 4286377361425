import React, { useEffect } from "react";

const NegotiateData = ({ eventData, setEventData }) => {
  useEffect(() => {
    const updatedServicesData = eventData.vendors.flatMap((vendor) =>
      vendor.services.map((service) => {
        const existingService = eventData.servicesData?.find(
          (s) =>
            s.vendor === vendor.vendor && s.name === (service.name || service)
        );

        if (existingService) {
          // Ensure `hasMoreInfo` is set to false if not already present
          return {
            ...existingService,
            hasMoreInfo:
              existingService.hasMoreInfo !== undefined
                ? existingService.hasMoreInfo
                : false, // Default to false if undefined
          };
        }

        if (typeof service === "string") {
          return {
            vendor: vendor.vendor,
            name: service,
            budget: "",
            budgetAmount: "",
            minimumAmount: "",
            maximumAmount: "",
            isNegotiable: false,
            hasMoreInfo: false, // Initialize as false
            moreInfo: "",
          };
        }

        return {
          vendor: vendor.vendor,
          ...service,
          hasMoreInfo: false, // Initialize as false
        };
      })
    );

    // Avoid updating state if there's no actual change in servicesData
    if (
      JSON.stringify(eventData.servicesData) !==
      JSON.stringify(updatedServicesData)
    ) {
      setEventData((prevData) => ({
        ...prevData,
        servicesData: updatedServicesData,
      }));
    }
  }, [eventData.vendors, setEventData, eventData.servicesData]);

  // Handle changes for service data
  const handleServiceChange = (serviceIndex, changes) => {
    const updatedServicesData = eventData.servicesData.map((service, index) => {
      if (index === serviceIndex) {
        return {
          ...service,
          ...changes,
        };
      }
      return service;
    });

    setEventData({
      ...eventData,
      servicesData: updatedServicesData,
    });
  };

  const handleBudgetChange = (serviceIndex, field, value) => {
    let changes = { [field]: value };

    if (field === "budget") {
      if (value === "undecided") {
        changes = {
          budget: value,
          budgetAmount: undefined,
          minimumAmount: undefined,
          maximumAmount: undefined,
          isNegotiable: undefined,
        };
      } else if (value === "amount") {
        changes = {
          budget: value,
          budgetAmount: "",
          minimumAmount: undefined,
          maximumAmount: undefined,
          isNegotiable: false,
        };
      } else if (value === "estimate") {
        changes = {
          budget: value,
          budgetAmount: undefined,
          minimumAmount: "",
          maximumAmount: "",
          isNegotiable: undefined,
        };
      }
    }

    handleServiceChange(serviceIndex, changes);
  };

  return (
    <>
      {eventData.servicesData.map((service, index) => (
        <div key={`${service.vendor}-${service.name}-${index}`}>
          <div className="vendor-form-items mb-4">
            <div className="form-items mb-0">
              <p className="negotiate-heading underline-style">
                {service.name}
              </p>

              <div className="mb-3">
                <div className="form-items">
                  <label className="form-label neg-label">
                    What is your budget?
                  </label>
                  <div className="dropdown">
                    <select
                      className="form-control neg-input"
                      name={`budget-${service.vendor}`}
                      value={service.budget || ""}
                      onChange={(e) =>
                        handleBudgetChange(index, "budget", e.target.value)
                      }
                    >
                      <option value="undecided">I'm Open To Suggestions</option>
                      <option value="amount">A Specific Amount</option>
                      <option value="estimate">An Estimate</option>
                    </select>
                  </div>
                </div>

                {service.budget === "amount" && (
                  <>
                    <div className="form-items">
                      <label className="form-label mb-0 neg-label">
                        Amount
                      </label>
                      <div className="input-container mt-3">
                        <span className="input-prefix">&#x20A6;</span>
                        <input
                          type="number"
                          min="0"
                          name={`budgetAmount-${service.vendor}`}
                          placeholder="Please Specify Amount"
                          className="input instagram-input height-4 neg-input"
                          value={service.budgetAmount || ""}
                          onChange={(e) =>
                            handleBudgetChange(
                              index,
                              "budgetAmount",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="form-items mb-1 mt-4">
                      <label className="form-label neg-label">
                        Is this negotiable?
                      </label>
                      <div className="d-flex">
                        <label className="form-label radio-label d-flex align-items-center neg-label">
                          Yes
                          <input
                            type="radio"
                            name={`${service.name}-${service.vendor}`}
                            value="yes"
                            checked={service.isNegotiable === true}
                            onChange={() =>
                              handleBudgetChange(index, "isNegotiable", true)
                            }
                            className="radio-input"
                          />
                        </label>
                        <label className="form-label radio-label d-flex align-items-center neg-label">
                          No
                          <input
                            type="radio"
                            name={`${service.name}-${service.vendor}`}
                            value="no"
                            checked={service.isNegotiable === false}
                            onChange={() =>
                              handleBudgetChange(index, "isNegotiable", false)
                            }
                            className="radio-input"
                          />
                        </label>
                      </div>
                    </div>
                  </>
                )}

                {service.budget === "estimate" && (
                  <>
                    <label className="form-label mb-0 mt-3 neg-label">
                      Minimum Amount
                    </label>
                    <div className="input-container mt-3">
                      <span className="input-prefix">&#x20A6;</span>
                      <input
                        type="number"
                        name={`minimumBudget-${service.vendor}`}
                        min="0"
                        placeholder="Minimum Amount"
                        className="input instagram-input height-4 neg-input"
                        value={service.minimumAmount || ""}
                        onChange={(e) =>
                          handleBudgetChange(
                            index,
                            "minimumAmount",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <label className="form-label mb-0 mt-3 neg-label">
                      Maximum Amount
                    </label>
                    <div className="input-container mt-3">
                      <span className="input-prefix">&#x20A6;</span>
                      <input
                        type="number"
                        min="0"
                        name={`maximumBudget-${service.vendor}`}
                        placeholder="Maximum Amount"
                        className="input instagram-input height-4 neg-input"
                        value={service.maximumAmount || ""}
                        onChange={(e) =>
                          handleBudgetChange(
                            index,
                            "maximumAmount",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="form-items mb-1">
                <label className="form-label neg-label">
                  Do you have any more information to share with the vendor for{" "}
                  {service.name}?
                </label>
                <div className="d-flex">
                  <label className="form-label radio-label d-flex align-items-center neg-label">
                    Yes
                    <input
                      type="radio"
                      name={`hasMoreInfo-${service.name}-${service.vendor}`}
                      checked={service.hasMoreInfo === true}
                      onChange={() =>
                        handleServiceChange(index, {
                          hasMoreInfo: true,
                        })
                      }
                      className="radio-input"
                    />
                  </label>
                  <label className="form-label radio-label d-flex align-items-center neg-label">
                    No
                    <input
                      type="radio"
                      name={`hasMoreInfo-${service.name}-${service.vendor}`}
                      checked={service.hasMoreInfo === false}
                      onChange={() =>
                        handleServiceChange(index, {
                          hasMoreInfo: false,
                        })
                      }
                      className="radio-input"
                    />
                  </label>
                </div>

                {service.hasMoreInfo && (
                  <textarea
                    className="form-control more-text neg-input"
                    type="text"
                    id={`moreInfo-${service.name}-${service.vendor}`}
                    name={`moreInfo-${service.name}-${service.vendor}`}
                    rows={4}
                    placeholder={`Additional information about ${service.name}`}
                    value={service.moreInfo || ""}
                    onChange={(e) =>
                      handleServiceChange(index, {
                        moreInfo: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default NegotiateData;
