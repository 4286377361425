import "./App.css";
import PageLayout from "./component/Event-Details/PageLayout";
import SignUpForm from "./component/SignUpForm/SignUpForm";
import Soon from "./component/Soon/Soon";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Soon />,
    },
    {
      path: "/register-vendor",
      element: <SignUpForm />,
    },
    {
      path: "/event-details",
      element: <PageLayout />,
    },
  ]);
  return (
    <HelmetProvider> {/* Wrap your app in HelmetProvider */}
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </HelmetProvider>
  );
}

export default App;
