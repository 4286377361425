import "./Header.css";
import img1 from "../../assets/Rectangle 4.png";
import img2 from "../../assets/Rectangle 5.png";
import img3 from "../../assets/Rectangle 27.png";
import img4 from "../../assets/Rectangle 28.png";
import Form from "./Form/Form";

export default function Header() {
  return (
    <div className="header d-flex justify-content-between">
      <div className="form-wrapper">
        <h1>
          Get Notified <br /> When We Launch
        </h1>

        <Form />

        <p className="quickp-desc">
        Quickplanners quickly connects you with reliable, thoroughly vetted vendors by sharing your event details upfront, ensuring only those who meet your terms will reach out.
        </p>
      </div>

      <div className="hero d-flex justify-content-center">
        <div className="hero-circle"></div>
        <div className="hero-wrapper">
          <img src={img1} alt="Event planning" className="first-img" />
          <img src={img2} alt="Event planning" />

          <div className="small-img one">
            <img src={img3} alt="Event planning" />
          </div>
          <div className="small-img two">
            <img src={img4} alt="Event planning" />
          </div>
        </div>
      </div>
    </div>
  );
}
