import React from "react";
import VendorData from "./Data/VendorData";

const SelectVendor = ({ eventData, setEventData, vendors, categoryError }) => {
  return (
    <div className="">
      <h2 className="main-head">Select Vendors</h2>
      <p className="layout-text">Select the vendors you want for your event.</p>

      <div className="form-content">
        <div className="form-scroll">
          <div className="form-div">
            <form>
              <VendorData
                eventData={eventData}
                setEventData={setEventData}
                vendors={vendors}
                categoryError={categoryError}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectVendor;
