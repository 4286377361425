import React from "react";
import NegotiateData from "./Data/NegotiateData";

const Negotiate = ({ eventData, setEventData }) => {
  return (
    <div className="">
      <h2 className="main-head">Cut Negotiation Time</h2>
      <p className="layout-text">
        The information you provide below will help you cut negotiation time by
        connecting only with vendors who can deliver on your terms.
      </p>

      <div className="form-content">
        <div className="form-scroll">
          <div className="form-div">
            <form>
              <NegotiateData
                eventData={eventData}
                setEventData={setEventData}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Negotiate;
