export default function Days({ label, onChange, checked }) {
  return (
    <div className="mb-2 d-flex align-items-center justify-content-between">
      <label className="check-label">{label}</label>
      <div className="d-flex ms-5">
        <label className="form-label radio-label d-flex align-items-center">
          Yes
          <input
            type="radio"
            name={label}
            value="yes"
            onChange={onChange}
            checked={checked}
            className="radio-input"
          />
        </label>
        <label className="form-label radio-label d-flex align-items-center">
          No
          <input
            type="radio"
            name={label}
            value="no"
            checked={!checked}
            className="radio-input"
            onChange={onChange}
          />
        </label>
      </div>
    </div>
  );
}
