import React from "react";
import Days from "./SignUpData/Days";

function AvailableForm({ formData, setFormData, states, budgets }) {
  const daysOfWeek = [
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
    "Sundays",
  ];

  const handleStateChange = (e) => {
    const { name, checked } = e.target;

    if (checked && formData.additionalStates.includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        additionalStates: prevData.additionalStates.filter(
          (additionalState) => additionalState !== name
        ),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        additionalStates: [...prevData.additionalStates, name],
      }));
    }
  };

  function formatCurrency(amount, currencyCode = "NGN", locale = "en-NG") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  }

  const handleDaysChange = (e) => {
    const { name, value } = e.target;

    if (value === "yes") {
      setFormData((prevData) => ({
        ...prevData,
        availableDays: [...prevData.availableDays, name],
      }));

      console.log("available days: " + formData.availableDays);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        availableDays: prevData.availableDays.filter((day) => day !== name),
      }));

      console.log("Formdata: " + formData);
    }
  };

  return (
    <div className="">
      <h2 className="main-head">Availability</h2>
      <div className="form-holder travel-para">
        <div className="d-flex align-items-center justify-content-between">
          <label className="form-label mb-0">Available for travel?</label>
          <div className="d-flex ms-5">
            <label className="form-label mb-0 radio-label d-flex align-items-center">
              Yes
              <input
                type="radio"
                name="availability"
                value="yes"
                checked={formData.canTravel}
                onChange={(e) => {
                  setFormData({ ...formData, canTravel: true });
                }}
                className="radio-input"
              />
            </label>
            <label className="form-label mb-0 radio-label d-flex align-items-center">
              No
              <input
                type="radio"
                name="availability"
                value="no"
                checked={!formData.canTravel}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    canTravel: false,
                    additionalStates: [],
                    budget: "",
                  });
                }}
                className="radio-input"
              />
            </label>
          </div>
        </div>

        {formData.canTravel && (
          <>
            <div className="form-items mt-4">
              <label className="form-label">Select states</label>
              <div className="state-content">
                {states
                  ?.filter(
                    (state) => state?.name !== formData?.stateOfResidence
                  )
                  .map((state) => (
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <label className="check-label">{state.name}</label>
                      <div className="d-flex ms-5">
                        <label
                          key={state.code}
                          className="check-label radio-label d-flex align-items-center"
                        >
                          <input
                            type="radio"
                            name={state.name}
                            value="yes"
                            onClick={handleStateChange}
                            checked={formData?.additionalStates.includes(
                              state.name
                            )}
                            className="radio-input"
                          />
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="form-items">
              <label className="form-label">
                The minimum budget required for Out-of-state services (including
                cost of transport)
              </label>
              <div className="dropdown">
                <select
                  name="budget"
                  value={formData?.budget || ""}
                  placeholder="Select your budget"
                  onChange={(e) =>
                    setFormData({ ...formData, budget: e.target.value })
                  }
                >
                  <option value="">Select your budget</option>
                  {budgets.map((data) => (
                    <option value={data.id} className="bold-600">
                      From <span className> {formatCurrency(data.name)} </span>{" "}
                      above
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
      </div>

      <p className="form-label w-100">
        Select the days you are available to receive jobs:
      </p>

      <div className="available-days">
        {daysOfWeek.map((day) => (
          <div key={day} className="service-item">
            <Days
              label={day}
              onChange={handleDaysChange}
              checked={formData?.availableDays.includes(day)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AvailableForm;
