import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import BusinessDetailsForm from "./BusinessDetailsForm";
import AvailableForm from "./AvailableForm";
import PortfolioForm from "./PorfolioForm";
import SignUpSuccessful from "./SignUpSuccessful";
import { getBudgets, getServices, getStates } from "../../mockApi";
import blueLogo from "../../assets/blueLogo.svg";
import back from "../../assets/back-arrow.svg";
import next from "../../assets/forward-arrow.svg";
import "./SignUpForm.css";
import { Helmet } from "react-helmet-async";

const whyRegister = [
  { title: "Cut negotiation time and close deals faster" },
  {
    title:
      "Reach genuine clients who are actively searching for vendors like you.",
  },
  { title: "Increase your bookings and expand your business" },
  { title: "Enhance your credibility with a professional platform presence" },
  { title: "Get a free business page to showcase your services" },
];

const FormTitles = ["Business Details", "Availability", "Portfolio"];

export default function SignUpForm() {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    businessName: "",
    stateOfResidence: "",
    registrationNumber: "",
    isBusinessRegistered: true,
    canTravel: false,
    phone: "",
    email: "",
    budget: "",
    additionalStates: [],
    instagram: "",
    website: "",
    services: [],
    availableDays: [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
    ],
    images: [],
  });
  const [states, setStates] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageErrors, setImageErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [services, setServices] = useState([]);

  const days = [
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
    "Sundays",
  ];

  useEffect(() => {
    getStates().then((name) => setStates(name));
    getBudgets().then((data) => setBudgets(data));
    getServices().then((data) => setServices(data));
  }, []);

  const validateBusinessDetails = () => {
    const newErrors = {};
    const phoneRegex = /^(\+?234|0)[789]\d{9}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const addError = (field, message) => {
      newErrors[field] = message;
      toast.error(message);
    };

    if (!formData.businessName) {
      addError("businessName", "Business name is required");
    }

    if (formData.isBusinessRegistered && !formData.registrationNumber) {
      addError("registrationNumber", "Registration number is required");
    }

    if (!formData.phone.trim()) {
      addError("phone", "Phone number is required");
    } else if (!phoneRegex.test(formData.phone.trim())) {
      addError(
        "phone",
        "Invalid phone number format. Use format (e.g., 08012345678 or +2348012345678)"
      );
    }

    if (!formData.email.trim()) {
      addError("email", "Email is required");
    } else if (!emailRegex.test(formData.email.trim())) {
      addError("email", "Invalid email format");
    }

    if (!formData.instagram) {
      addError("instagram", "Instagram handle is required");
    }

    if (formData.services.length < 1) {
      addError("services", "Select at least one service");
    } else if (formData.services.length > 2) {
      addError("services", "Select a maximum of two services");
    }

    setErrors(newErrors);

    setTimeout(() => setErrors({}), 6000);
    return Object.keys(newErrors).length === 0;
  };

  const validatePortfolio = () => {
    const newErrors = {};
    if (!formData.images || formData.images.length === 0)
      newErrors.images = "Please add at least one image";
    toast.error(newErrors.images);
    setImageErrors(newErrors);
    setTimeout(() => setImageErrors({}), 2000);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (page === 0 && !validateBusinessDetails()) return;
    if (page === FormTitles.length - 1) {
      if (!validatePortfolio()) return;
      try {
        setLoading(true);
        const transformedData = {
          business_name: formData.businessName,
          business_reg_number: formData.registrationNumber || "",
          business_location: formData.stateOfResidence,
          business_email: formData.email,
          business_phone: formData.phone,
          instagram: formData.instagram,
          budget_id: formData.budget,
          website: formData.website || "",
          services: JSON.stringify(formData.services),
          travel_locations: JSON.stringify(formData.additionalStates),
          isBusinessRegistered: formData.isBusinessRegistered ? "1" : "0",
          can_travel: formData.canTravel ? "1" : "0",
          days: JSON.stringify(formData.availableDays),
          isAvailable: JSON.stringify(
            days.map((day) =>
              formData.availableDays.includes(day) ? "1" : "0"
            )
          ),
          file_url: JSON.stringify(formData.images),
        };
        const response = await axios.post(
          "https://qp-api-4681075e5ca6.herokuapp.com/api/vendor/signup",
          transformedData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (response.data.status === "success") {
          setSubmitted(true);
        }
      } catch (error) {
        if (error.response.status === 401) {
          if (error.response && error.response.data.message) {
            if (Array.isArray(error.response.data.message)) {
              const errorMessage = error.response.data.message;
              errorMessage.forEach((mes) => toast.error(mes));
            } else {
              toast.error(error.response.data.message);
            }
          } else {
            toast.error("Error submitting form. Please try again.");
          }
        } else if (error.response.status === 500) {
          console.log(error);
          toast.error("Error submitting form. Please try again.");
        }
        return;
      } finally {
        setLoading(false);
      }
    }
    setPage((currPage) => currPage + 1);
  };

  const PageDisplay = () => {
    if (submitted) return <SignUpSuccessful />;
    switch (page) {
      case 0:
        return (
          <BusinessDetailsForm
            formData={formData}
            setFormData={setFormData}
            states={states}
            errors={errors}
            services={services}
          />
        );
      case 1:
        return (
          <AvailableForm
            formData={formData}
            budgets={budgets}
            setFormData={setFormData}
            states={states}
          />
        );
      case 2:
        return (
          <PortfolioForm
            formData={formData}
            setFormData={setFormData}
            imageErrors={imageErrors}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      {/* Helmet for setting the page title */}
      <Helmet>
        <title>Register as a vendor on Quickplanners</title>
      </Helmet>
      <section className="d-flex vendor-registeration">
        <div className="side">
          <div className="logo">
            <Logo />
          </div>
          <h2 className="sub-head">Why register?</h2>
          <div className="register-reason">
            <div>
              {whyRegister.map((item, index) => (
                <div className="reason-item" key={index}>
                  <div className="reason-icon">
                    <p className="sn">{index + 1}</p>
                  </div>
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="main">
          <Link to="/">
            <img src={blueLogo} alt="Logo" className="blue-logo" />
          </Link>
          <h2 className="main-head">Register as a vendor</h2>
          <p className="sub-para">
            {submitted
              ? "Registration completed"
              : "Complete the form below to register as a vendor on Quickplanners"}
          </p>
          <div className="stepper">
            {FormTitles.map((title, index) => (
              <div
                key={title}
                className={`step ${page > index ? "complete" : ""} ${
                  page === index ? "active" : ""
                }`}
              >
                <div className="step-number">
                  {page > index ? (
                    <span>&#10003;</span>
                  ) : page === index ? (
                    <span>&#x3f;</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
            <div
              className="progress-bar"
              style={{
                width: `calc(100% - ${40}px)`,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div
                className="progress"
                style={{ width: `${(page / (FormTitles.length - 1)) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className="form-container">
            <div className="body">{PageDisplay()}</div>
            <div className="footer">
              <div className="d-flex justify-content-between align-items-center stepper-nav">
                {page > 0 && !submitted && (
                  <button
                    className="step-btn btn-ghost d-flex align-items-center gap-3"
                    onClick={() => setPage((p) => p - 1)}
                  >
                    <span>
                      <img src={back} alt="previous step arrow" />
                    </span>
                    Back
                  </button>
                )}
                <div></div>
                {submitted ? (
                  <Link to="/">
                    <button className="step-btn btn-full d-flex align-items-center gap-3 complete">
                      Done
                    </button>
                  </Link>
                ) : (
                  <button
                    className={`step-btn btn-full d-flex align-items-center gap-3 ${
                      page === FormTitles.length - 1 ? "complete" : ""
                    }`}
                    onClick={handleNext}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : page === FormTitles.length - 1 ? (
                      "Submit"
                    ) : (
                      <>
                        Next <img src={next} alt="next step arrow" />
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}
