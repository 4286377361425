import React from "react";
import Services from "./SignUpData/Services";

function BusinessDetailsForm({
  formData,
  setFormData,
  states,
  errors,
  services,
}) {
  const handleServiceChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      if (formData?.services.length < 2) {
        setFormData((prevData) => ({
          ...prevData,
          services: [...prevData.services, name],
        }));

        console.log("businessData1: " + formData?.services);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        services: prevData.services.filter((service) => service !== name),
      }));
      console.log("businessData2: " + formData?.services);
    }
    console.log("businessData3: " + formData?.services);
  };

  return (
    <div className="">
      <h2 className="main-head">Business Details</h2>
      <p className="bs-text">Tell us about your business</p>

      <div className="form-holder">
        <form>
          <div className="form-items required">
            <label className="form-label">Business/Brand Name</label>
            <input
              type="text"
              id="businessName"
              name="businessName"
              placeholder="Your brand name"
              value={formData.businessName}
              className={errors.businessName ? "error-input" : ""}
              onChange={(e) =>
                setFormData({ ...formData, businessName: e.target.value })
              }
            />
          </div>
          <div className="form-items">
            <label className="form-label">
              Is your business/brand registered?
            </label>
            <div className="d-flex ms-5">
              <label className="form-label radio-label d-flex align-items-center">
                Yes
                <input
                  type="radio"
                  name="brandRegistered"
                  value="yes"
                  checked={formData.isBusinessRegistered}
                  onChange={(e) =>
                    setFormData({ ...formData, isBusinessRegistered: true })
                  }
                  className="radio-input"
                />
              </label>
              <label className="form-label radio-label d-flex align-items-center">
                No
                <input
                  type="radio"
                  name="brandRegistered"
                  value="no"
                  checked={!formData.isBusinessRegistered}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      isBusinessRegistered: false,
                      registrationNumber: "",
                    })
                  }
                  className="radio-input"
                />
              </label>
            </div>
            {formData?.isBusinessRegistered && (
              <>
                <input
                  type="number"
                  name="registrationNumber"
                  placeholder="RC/BN Number"
                  value={formData.registrationNumber}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      registrationNumber: e.target.value,
                    })
                  }
                />
              </>
            )}
          </div>
          <div className="form-items required">
            <label className="form-label">Business Location</label>
            <div className="dropdown">
              <select
                name="stateOfResidence"
                value={formData?.stateOfResidence || ""}
                onChange={(e) =>
                  setFormData({ ...formData, stateOfResidence: e.target.value })
                }
              >
                <option value="">Select State</option>
                {states?.map((state) => (
                  <option key={state.code} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-items required">
            <label className="form-label">Business Phone</label>
            <input
              type="tel"
              name="phone"
              placeholder="0801234......"
              value={formData?.phone || ""}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
          </div>
          <div className="form-items required">
            <label className="form-label">Business Email</label>
            <input
              type="email"
              name="email"
              placeholder="yourname@example.com"
              value={formData?.email || ""}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div className="form-items required">
            <label className="form-label">Instagram</label>
            <div className="input-container">
              <span className="input-prefix">@</span>
              <input
                type="text"
                name="instagram"
                placeholder="yourhandle"
                value={formData?.instagram || ""}
                className="input instagram-input"
                onChange={(e) =>
                  setFormData({ ...formData, instagram: e.target.value })
                }
              />
            </div>
          </div>
          <div className="form-items">
            <label className="form-label">Website (this is optional)</label>
            <input
              type="url"
              placeholder="https://www.yourwebsite.com"
              name="website"
              value={formData?.website || ""}
              onChange={(e) =>
                setFormData({ ...formData, website: e.target.value })
              }
            />
          </div>
          <div className="form-items required">
            <label className="form-label">
              Please select one or two services that you offer:
            </label>
            <div className="service-content">
              {services.map((item) => (
                <div key={item.id} className="service-item">
                  <Services
                    service={item.name}
                    onChange={handleServiceChange}
                    value={formData?.services}
                    checked={formData?.services.includes(item.name)}
                  />
                </div>
              ))}
            </div>
            <div className=" mt-3">
              {formData?.services.length === 2 && (
                <p className="fs-4 text-success text-end">
                  Maximum of 2 services selected
                </p>
              )}

              <div> </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BusinessDetailsForm;
