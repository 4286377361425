import React from "react";
import success from "../../assets/reg success.svg";
import { Link } from "react-router-dom";
import googlePlay from "../../assets/playstore-6ad74803 1.svg";
import appStore from "../../assets/appstore-dd5b8404 1.svg";

const Successful = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img
        src={success}
        alt="Request Submitted Succesfully"
        className="reg-success-icon"
      />
      <h1 className="req-success-heading">Request Submitted Succesfully</h1>
      <p className="reg-success-para text-center">
        Download our app now to view vendor applications. Sign up using the
        email you provided.
      </p>
      <div className=" d-flex justify-content-between align-items-center gap-5 text-center mt-5">
        <Link>
          <img src={googlePlay} alt="google palystore button" />
        </Link>
        <Link>
          <img src={appStore} alt="app store button" />
        </Link>
      </div>
    </div>
  );
};

export default Successful;
