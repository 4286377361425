import React from "react";
import "./EventInfo.css";

const EventInfo = ({
  eventData,
  setEventData,
  states,
  errors,
  guests,
  eventTypes,
}) => {
  const handleDateChange = (index, date) => {
    if (index === "eventDate")
      setEventData({
        ...eventData,
        eventDate: date,
        startDate: null,
        endDate: null,
      });

    if (index === "startDate")
      setEventData({
        ...eventData,
        eventDate: null,
        startDate: date,
      });

    if (index === "endDate") {
      setEventData({
        ...eventData,
        eventDate: null,
        endDate: date,
      });
    }
  };

  const today = new Date().toISOString().split("T")[0];

  const handleSelectionChange = (e) => {
    const selection = e.target.value;

    if (selection === "single") {
      setEventData({
        ...eventData,
        startDate: null,
        endDate: null,
        eventDuration: selection,
      });
    } else if (selection === "multiple") {
      setEventData({ ...eventData, eventDate: null, eventDuration: selection });
    }
  };

  return (
    <div className="">
      <h2 className="main-head">Event Details</h2>
      <p className="layout-text">Share your event idea with the vendors.</p>

      <div className="form-holder">
        <form>
          <div className="form-items required">
            <label className="form-label mb-1">
              Assign a Name to This Event
            </label>
            <p className="layout-text m-0 fs-4">
              Assign a name to help you easily remember this request. This name
              is for your reference only and won't be visible to vendors.
            </p>
            <>
              <input
                className="mt-3"
                type="text"
                name="eventName"
                placeholder="Folake’s wedding"
                value={eventData.eventName || ""}
                onChange={(e) =>
                  setEventData({
                    ...eventData,
                    eventName: e.target.value,
                  })
                }
              />
            </>
          </div>

          <div className="form-items required">
            <label className="form-label">Type of Event</label>
            <>
              <div className="dropdown">
                <select
                  name="eventType"
                  value={eventData?.eventType || ""}
                  onChange={(e) =>
                    setEventData({
                      ...eventData,
                      eventType: e.target.value,
                    })
                  }
                >
                  <option value="">Select Event Type</option>
                  {eventTypes?.map((event) => (
                    <option key={event.id} value={event.name}>
                      {event.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
            {eventData?.eventType === "Other" && (
              <input
                className="mt-3"
                type="text"
                name="otherEventType"
                placeholder="Please Specify The Event Type"
                value={eventData.otherEventType || ""}
                onChange={(e) =>
                  setEventData({
                    ...eventData,
                    otherEventType: e.target.value,
                  })
                }
              />
            )}
          </div>

          <div className="form-items required">
            <label className="form-label">Event Duration</label>
            <>
              <div className="dropdown mb-4">
                <select
                  className="form-control"
                  value={eventData.eventDuration}
                  onChange={handleSelectionChange}
                >
                  <option value="">Select Event Duration</option>
                  <option value="single">Single Date</option>
                  <option value="multiple">Multiple Days</option>
                </select>
              </div>
            </>
            {eventData.eventDuration === "single" && (
              <>
                <div className="date-picker-wrapper">
                  <input
                    type="date"
                    id="eventDate"
                    name="eventDate"
                    min={today}
                    placeholder="Select A Date"
                    value={eventData.eventDate || ""}
                    //   className={errors.eventDate ? "error-input" : ""}
                    className="custom-date-input"
                    onChange={(e) =>
                      handleDateChange("eventDate", e.target.value)
                    }
                  />
                </div>
              </>
            )}

            {eventData.eventDuration === "multiple" && (
              <>
                <>
                  <div className="form-items ">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      min={today}
                      value={eventData.startDate || ""}
                      //   className={errors.eventDate ? "error-input" : ""}
                      className="custom-date-input"
                      onChange={(e) =>
                        handleDateChange("startDate", e.target.value)
                      }
                    />
                  </div>
                </>
                <>
                  <div className="form-items ">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      min={today}
                      value={eventData.endDate || ""}
                      //   className={errors.eventDate ? "error-input" : ""}
                      className="custom-date-input"
                      onChange={(e) =>
                        handleDateChange("endDate", e.target.value)
                      }
                    />
                  </div>
                </>
              </>
            )}
          </div>

          <div className="form-items required">
            <label className="form-label">State</label>
            <>
              <div className="dropdown">
                <select
                  name="state"
                  value={eventData?.state || ""}
                  onChange={(e) =>
                    setEventData({ ...eventData, state: e.target.value })
                  }
                >
                  <option value="">Select State</option>
                  {states?.map((state) => (
                    <option key={state.id} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          </div>
          <div className="form-items required">
            <label className="form-label">Number of Guests</label>
            <>
              <div className="dropdown">
                <select
                  name="guests"
                  value={eventData?.guests || ""}
                  onChange={(e) =>
                    setEventData({ ...eventData, guests: e.target.value })
                  }
                >
                  <option value="">Select Number of Guests</option>
                  {guests?.map((guest) => (
                    <option key={guest.code} value={guest.name}>
                      {guest.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
            {eventData?.guests === "Other" && (
              <input
                className="mt-3"
                type="text"
                name="otherGuests"
                placeholder="Please specify the number of guests"
                value={eventData.otherGuests || ""}
                onChange={(e) =>
                  setEventData({
                    ...eventData,
                    otherGuests: e.target.value,
                  })
                }
              />
            )}
          </div>

          <div className="form-items required">
            <label className="form-label">Do you currently have a venue?</label>
            <div className="d-flex mb-4">
              <label className="label radio-label d-flex align-items-center">
                Yes
                <input
                  type="radio"
                  name="hasVenue"
                  value="yes"
                  checked={eventData.hasVenue}
                  onChange={(e) =>
                    setEventData({ ...eventData, hasVenue: true })
                  }
                  className="radio-input"
                />
              </label>
              <label className="label radio-label d-flex align-items-center">
                No
                <input
                  type="radio"
                  name="hasVenue"
                  value="no"
                  checked={!eventData.hasVenue}
                  onChange={(e) =>
                    setEventData({
                      ...eventData,
                      hasVenue: false,
                      venueName: "",
                      venueLocation: "",
                      venueType: "",
                    })
                  }
                  className="radio-input"
                />
              </label>
            </div>
            <div className="background">
              {eventData?.hasVenue && (
                <>
                  <div className="form-items">
                    <label className="form-label opt-label mb-0 ">
                      Name of Venue
                    </label>
                    <input
                      className="venue fs-4"
                      type="text"
                      name="venueName"
                      placeholder="Name Of Venue"
                      value={eventData.venueName}
                      onChange={(e) =>
                        setEventData({
                          ...eventData,
                          venueName: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-items">
                    <label className="form-label opt-label mb-0">
                      Location Of Venue
                    </label>
                    <input
                      className="venue fs-4"
                      type="text"
                      name="venueLocation"
                      placeholder="Venue Location"
                      value={eventData.venueLocation}
                      onChange={(e) =>
                        setEventData({
                          ...eventData,
                          venueLocation: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-items my-2">
                    <label className="form-label opt-label">Venue Type</label>
                    <div className="d-flex">
                      <label className="label radio-label d-flex align-items-center">
                        Indoor
                        <input
                          type="radio"
                          name="venueType"
                          value="Indoor"
                          checked={eventData.venueType === "Indoor"}
                          onChange={(e) =>
                            setEventData({
                              ...eventData,
                              venueType: e.target.value,
                            })
                          }
                          className="radio-input"
                        />
                      </label>
                      <label className="label radio-label d-flex align-items-center">
                        Outdoor
                        <input
                          type="radio"
                          name="venueType"
                          value="Outdoor"
                          checked={eventData.venueType === "Outdoor"}
                          onChange={(e) =>
                            setEventData({
                              ...eventData,
                              venueType: e.target.value,
                            })
                          }
                          className="radio-input"
                        />
                      </label>
                      <label className="label radio-label d-flex align-items-center">
                        Other
                        <input
                          type="radio"
                          name="venueType"
                          value="Other"
                          checked={eventData.venueType === "Other"}
                          onChange={(e) =>
                            setEventData({
                              ...eventData,
                              venueType: e.target.value,
                            })
                          }
                          className="radio-input"
                        />
                      </label>
                    </div>
                  </div>
                  {eventData.venueType === "Other" && (
                    <>
                      <input
                        className="venue ms-5 mt-2"
                        type="text"
                        name="otherVenueType"
                        placeholder="Please Specify The Venue Type"
                        value={eventData.otherVenueType || ""}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            otherVenueType: e.target.value,
                          })
                        }
                      />
                    </>
                  )}
                </>
              )}

              {!eventData?.hasVenue && (
                <>
                  <div className="form-items my-2">
                    <label className="form-label opt-label">
                      Proposed venue Type
                    </label>
                    <div className="d-flex">
                      <label className="label radio-label d-flex align-items-center">
                        Indoor
                        <input
                          type="radio"
                          name="proposedVenueType"
                          value="Indoor"
                          checked={eventData.proposedVenueType === "Indoor"}
                          onChange={(e) =>
                            setEventData({
                              ...eventData,
                              proposedVenueType: e.target.value,
                            })
                          }
                          className="radio-input fs-4"
                        />
                      </label>
                      <label className="label radio-label d-flex align-items-center">
                        Outdoor
                        <input
                          type="radio"
                          name="proposedVenueType"
                          value="Outdoor"
                          checked={eventData.proposedVenueType === "Outdoor"}
                          onChange={(e) =>
                            setEventData({
                              ...eventData,
                              proposedVenueType: e.target.value,
                            })
                          }
                          className="radio-input"
                        />
                      </label>
                      <label className="label radio-label d-flex align-items-center">
                        Other
                        <input
                          type="radio"
                          name="proposedVenueType"
                          value="Other"
                          checked={eventData.proposedVenueType === "Other"}
                          onChange={(e) =>
                            setEventData({
                              ...eventData,
                              proposedVenueType: e.target.value,
                            })
                          }
                          className="radio-input"
                        />
                      </label>
                    </div>
                  </div>
                  {eventData.proposedVenueType === "Other" && (
                    <>
                      <input
                        className="venue mt-2"
                        type="text"
                        name="otherProposedVenueType"
                        placeholder="Please Specify The Venue Type"
                        value={eventData.otherProposedVenueType || ""}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            otherProposedVenueType: e.target.value,
                          })
                        }
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="form-items ">
            <label className="form-label">Additional Information</label>
            <textarea
              type="text"
              id="additionalInfo"
              name="additionalInfo"
              placeholder="What else do you want the vendor To know about your event?"
              value={eventData.additionalInfo}
              className={errors.additionalInfo ? "error-input" : ""}
              onChange={(e) =>
                setEventData({ ...eventData, additionalInfo: e.target.value })
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventInfo;
