import Button from "../Button/Button";
import Logo from "../Logo/Logo";
import "./Nav.css";
import menu from "../../assets/menu-svgrepo-com.svg";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Steps from "../Steps/Steps";
import Toast from "../Toast/Toast";
import { Link } from "react-router-dom";
import ButtonGhost from "../Button/ButtonGhost";

export default function Nav(props) {
  const [menuActive, setMenuActive] = useState(false);
  const [aboutShow, setAboutShow] = useState(false);
  const [howShow, setHowShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [toasts, setToasts] = useState([]);

  const handleRemoveToast = (id) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  function toggleMenu() {
    console.log(menuActive);
    setMenuActive(!menuActive);
  }

  function closeMenu() {
    setMenuActive(false);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuActive &&
        !event.target.closest(".mobile-nav") &&
        !event.target.closest(".menu")
      ) {
        setMenuActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuActive]);

  return (
    <div className="nav-wrapper">
      <nav className="d-flex justify-content-between align-items-center nav">
        <Logo />

        <div className="nav-list">
          <ul className="d-flex align-items-center nav-items">
            <li className="mx-4" onClick={() => setAboutShow(true)}>
              About
            </li>
            <li className="mx-4" onClick={() => setHowShow(true)}>
              How it works
            </li>
            <li className="" onClick={() => setContactShow(true)}>
              Contact
            </li>
          </ul>
        </div>

        <Modal
          {...props}
          size="lg"
          className="modal"
          show={aboutShow}
          onHide={() => setAboutShow(false)}
          aria-labelledby="about-us"
          centered
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title id="about-us" className="modal-title">
              About Quickplanners
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p className="about-us-text">
              Quickplanners quickly connects you with reliable, thoroughly
              vetted vendors by sharing your event details upfront, ensuring
              only those who meet your terms will reach out. For added security,
              we offer an optional escrow system that refunds 25% if a vendor
              fails to deliver.
            </p>
          </Modal.Body>
        </Modal>

        <Modal
          {...props}
          size="lg"
          className="modal"
          show={howShow}
          onHide={() => setHowShow(false)}
          aria-labelledby="how-it-works"
          centered
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title id="how-it-works" className="modal-title">
              How it Works
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Steps />
          </Modal.Body>
        </Modal>

        <Modal
          {...props}
          size="lg"
          className="modal"
          show={contactShow}
          onHide={() => setContactShow(false)}
          aria-labelledby="about-us"
          centered
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title id="contact-us" className="modal-title">
              Contact Us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <label className="label">Instagram:</label>
            <p className="contact-text">
              https://www.instagram.com/quickplanners
            </p>
            <label className="label">Whatsapp/Call:</label>
            <p className="contact-text">+234 810 743 8151</p>
            <label className="label">Facebook: </label>
            <p className="contact-text">
              https://www.facebook.com/quickplannersapp
            </p>
          </Modal.Body>
        </Modal>
        <div className="d-flex gap-3 align-content-center">
          <Link to="/register-vendor">
            <div className="reg-btn">
              <ButtonGhost>Register as a vendor</ButtonGhost>
            </div>
          </Link>
          <Link to="/event-details">
            <div className="reg-btn">
              <Button>Get started</Button>
            </div>
          </Link>
        </div>
        <img src={menu} alt="menu" className="menu" onClick={toggleMenu} />

        <div className={`mobile-nav ${menuActive ? "show" : ""}`}>
          <ul className="d-flex align-items-center nav-items">
            <li
              className="mx-4"
              onClick={() => {
                setAboutShow(true);
                closeMenu();
              }}
            >
              About
            </li>
            <li
              className="mx-4"
              onClick={() => {
                setHowShow(true);
                closeMenu();
              }}
            >
              How it works
            </li>
            <li
              className=""
              onClick={() => {
                setContactShow(true);
                closeMenu();
              }}
            >
              Contact
            </li>
          </ul>
          <Link to="/register-vendor">
            <div className="reg-btn">
              <ButtonGhost>Register as a vendor</ButtonGhost>
            </div>
          </Link>
          <Link to="/event-details">
            <div className="reg-btn">
              <Button>Get started</Button>
            </div>
          </Link>

          <Toast toasts={toasts} removeToast={handleRemoveToast} />
        </div>
      </nav>
    </div>
  );
}
