import "./Steps.css";

export default function Step() {
  const stepsDetails = [
    {
      title: "Provide Event Details",
      description:
        "Share key information about your event, such as the date, location, and any specific needs",
    },
    {
      title: "Specify Vendor Requirements",
      description:
        "Identify the type of event and the vendors you need, whether it's caterers, decorators, photographers, or others.",
    },
    {
      title: "Vendors Receive Your Request",
      description:
        "Verified vendors within your event location will receive your request, and those available and willing to meet your terms will submit their offers.",
    },
    {
      title: "Review and Compare Offers",
      description:
        "Receive offers from vendors that align with your criteria, including pricing. Compare the options and select the best fit for your event.",
    },
    {
      title: "Escrow Protection",
      description:
        "   By completing your booking through Quickplanners, you'll benefit from our escrow service, ensuring a partial refund if a vendor doesn't meet your expectations.",
    },
  ];
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-6">
          <h1 className="font-weight-bold steps-intro">
            Quickplanners is the ultimate platform for event organizers who
            settle for nothing less than excellence.
          </h1>
          <p className="offer-desc">
            We specialize in connecting you with verified vendors to ensure your
            event goes smoothly. With our escrow service, your satisfaction is
            guaranteed every step of the way.
          </p>
          <p className="offer-desc">
            At Quickplanners, we make the process seamless by instantly
            connecting you with reliable, thoroughly vetted vendors who align
            with your specific event needs. By sharing your event details
            upfront, we ensure that only vendors who meet your terms and
            standards will reach out. This tailored approach saves you time and
            effort while offering peace of mind.
          </p>
          <p className="offer-desc">
            With our secure escrow system, you can book with complete
            confidence. Payments are held safely until you confirm the vendor
            has met the agreed-upon standards, and if they don't, you're
            eligible for a refund.
          </p>
          <p className="offer-desc">
            Quickplanners ensures that every part of your event planning process
            is smooth, transparent, and stress-free.
          </p>
        </div>
        <div className="col-lg-6">
          <ul className="timeline">
            {stepsDetails.map((step, index) => (
              <li key={index}>
                <div className="timeline-icon">
                  <i className={`fas fa-step-`}>{index + 1}</i>
                </div>
                <div className="timeline-panel">
                  <h5 className="steps-title">{step.title}</h5>
                  <p className="para-steps">{step.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
