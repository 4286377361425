import React from "react";

const ContactDetails = ({ eventData, setEventData }) => {
  return (
    <div className="">
      <h2 className="main-head">Contact Details</h2>
      <p className="layout-text">
        To avoid vendors contacting you without your consent, we will not share
        your contact details with any vendor until you choose to work with them.
      </p>

      <div className="form-holder">
        <form>
          <div className="form-items">
            <label className="form-label">Full Name/Alias</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name or Alias"
              value={eventData.name}
              //   className={errors.name ? "error-input" : ""}
              onChange={(e) =>
                setEventData({ ...eventData, name: e.target.value })
              }
            />
            {/* {errors.name && (
              <span className="error-text">{errors.businessName}</span>
            )} */}
          </div>

          <div className="form-items">
            <label className="form-label">Phone Number</label>
            <input
              type="number"
              name="phone"
              min="0"
              placeholder="0801234......"
              value={eventData?.phone || ""}
              onChange={(e) =>
                setEventData({ ...eventData, phone: e.target.value })
              }
            />
            {/* {errors.phone && <span className="error-text">{errors.phone}</span>} */}
          </div>
          <div className="form-items">
            <label className="form-label">Email</label>
            <input
              type="email"
              name="email"
              placeholder="yourname@example.com"
              value={eventData?.email || ""}
              onChange={(e) =>
                setEventData({ ...eventData, email: e.target.value })
              }
            />
            {/* {errors.email && <span className="error-text">{errors.email}</span>} */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactDetails;
