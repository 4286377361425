export default function Services({ key, service, onChange, value, checked }) {
  return (
    <div className="mb-4 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-between w-100">
        <label
          className="check-label d-flex align-items-center "
          htmlFor={service}
        >
          {service}
        </label>
        <input
          key={key}
          type="checkbox"
          name={service}
          value={value || ""}
          onChange={onChange}
          className="radio-input"
          checked={checked}
        />
      </div>
    </div>
  );
}
