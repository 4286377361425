import React, { useEffect, useState } from "react";
import "./VendorData.css";

const VendorData = ({ eventData, setEventData, vendors, categoryError }) => {
  const [selectedServices, setSelectedServices] = useState({});

  useEffect(() => {
    const initialSelectedServices = {};
    eventData.vendors.forEach((vendorData) => {
      initialSelectedServices[vendorData.vendor] = vendorData.services.map(
        (service) => ({
          id: service.id,
          name: service.name,
        })
      );
    });
    setSelectedServices(initialSelectedServices);
  }, [eventData]);

  const toggleService = (vendorName, service) => {
    setSelectedServices((prevState) => {
      const vendorServices = prevState[vendorName] || [];
      const isSelected = vendorServices.some((s) => s.id === service.id);

      let updatedServices;
      if (isSelected) {
        updatedServices = vendorServices.filter((s) => s.id !== service.id);
      } else {
        updatedServices = [
          ...vendorServices,
          { id: service.id, name: service.name },
        ];
      }

      const updatedSelectedServices = {
        ...prevState,
        [vendorName]: updatedServices,
      };

      // Update eventData
      setEventData((prevEventData) => {
        const updatedVendors = Object.keys(updatedSelectedServices)
          .filter((vendor) => updatedSelectedServices[vendor].length > 0)
          .map((vendor) => ({
            vendor: vendor,
            services: updatedSelectedServices[vendor],
          }));

        return {
          ...prevEventData,
          vendors: updatedVendors,
        };
      });

      return updatedSelectedServices;
    });
  };

  useEffect(() => {
    console.log("Updated Event Data: ", eventData);
  }, [eventData]);

  return (
    <>
      {vendors?.map((vendor) => (
        <div className="form-items vendor-form-items" key={vendor.id}>
          <label className="form-label">{vendor.name}</label>
          <div className="d-flex align-items-center flex-wrap gap-2 wrap">
            {vendor.subs.data.map((service) => {
              const isSelected = selectedServices[vendor.name]?.some(
                (s) => s.id === service.id
              );
              return (
                <div
                  key={service.id}
                  className="service-overflow"
                  onClick={() => toggleService(vendor.name, service)}
                >
                  <div
                    className={`services-wrapper ${
                      isSelected ? "selected" : ""
                    }`}
                  >
                    <span>{isSelected ? "−" : "+"}</span>
                    {service.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default VendorData;
