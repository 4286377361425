import axios from "axios";
import { toast } from "react-toastify";

export const getGuests = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        guests: [
          { code: "Very Small", name: "Less than 50" },
          { code: "Small", name: "50 - 100" },
          { code: "Medium", name: "100 - 200" },
          { code: "High", name: "200 - 500" },
          { code: "Very High", name: "Above 500" },
          { code: "Specify", name: "Other" },
        ],
      });
    }, 1000);
  });
};

export const getEvents = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        events: [
          { code: "1", name: "Wedding" },
          { code: "2", name: "Birthday Party" },
          { code: "3", name: "Corporate & Professional" },
          { code: "4", name: "Bridal Shower" },
          { code: "5", name: "Baby Shower" },
          { code: "6", name: "Kids' Party" },
          { code: "7", name: "Anniversary Celebration" },
          { code: "8", name: "Graduation Party" },
          { code: "9", name: "Seminar" },
          { code: "10", name: "School Party" },
          { code: "11", name: "Award Party" },
          { code: "Specify", name: "Other" },
        ],
      });
    }, 1000);
  });
};

export const getVendors = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        vendors: [
          {
            vendor: "Food and Drinks",
            services: [
              "Cake/Desserts/Confectionaries",
              "Drinks/Ice Pops",
              "Cocktails/Mocktails",
              "Catering",
              "Small Chops",
              "Grills",
              "Food Props",
            ],
          },
          {
            vendor: "Attires, Outfits and Accessories",
            services: [
              "Bridal Accessories",
              "Bridal Robe",
              "Wedding Dress",
              "Suits for Men",
              "Corals",
              "Jewelry",
              "Bridal Stylist",
              "Bridal Wardrobe Manager",
              "Fabrics",
              "Aso-Ebi/Aso Oke",
              "Traditional Outfits/Accessories",
              "Tailoring",
              "Shoes",
              "Robes/Shape-wear/Lingerie",
            ],
          },
          {
            vendor: "Event Venues and Decoration",
            services: [
              "Event Venue/Marquees/Gardens",
              "Event Decorator",
              "Florist/Bouquets",
            ],
          },
          {
            vendor: "Beauty and Personal Care",
            services: [
              "Make-up",
              "Spa",
              "Henna",
              "Hair Dresser",
              "Barber",
              "Nails",
              "Massage",
              "Stylist",
              "Gele/Headtie",
              "Millinery",
              "Fascinators",
            ],
          },
          {
            vendor: "Entertainment and Music",
            services: [
              "DJ/Sound Engineer",
              "MC/Alaga",
              "Entertainment Group",
              "Comedian",
              "Musician",
              "Clown",
              "Costume Character",
              "Live Band",
              "Dance Lessons",
            ],
          },
          {
            vendor: "Photography and Videography",
            services: [
              "Photography/Videography",
              "Photobooth/360 booth",
              "Drone Shots",
            ],
          },
          {
            vendor: "Event Planning and Coordination",
            services: ["Event Planning", "Ushering/Coordination"],
          },
          {
            vendor: "Stationery, Invitations and Souvenirs",
            services: [
              "Invitations",
              "Billboards/Print Work",
              "Stationeries",
              "Souvenirs",
              "Graphic designs",
            ],
          },
          {
            vendor: "Rentals",
            services: [
              "Furniture and Seating",
              "Tents and Canopies",
              "Lighting and Audio Equipment",
              "Catering Equipment",
              "Decoration and Props",
              "Tableware and Linens",
              "Staging and Flooring",
              "Photo Booths and Props",
              "Heaters and Fans",
              "Projectors and Screens",
              "Transportation Rentals",
              "Portable Restrooms",
              "Tents and Awnings",
              "Games and Entertainment",
              "Stages and Platforms",
            ],
          },
          {
            vendor: "Lighting and Special Effects",
            services: ["Lighting", "Sound", "Special Effects"],
          },
          {
            vendor: "Transportation and Travel",
            services: [
              "Transport Services",
              "Chauffeur",
              "Car Rentals",
              "Travel Agent",
            ],
          },
          {
            vendor: "Accommodation and Hospitality",
            services: ["Hotel Booking", "Apartment Booking"],
          },
          {
            vendor: "Security and Emergencies",
            services: [
              "Security Guards",
              "Crowd Control",
              "Access Control",
              "Surveillance and Monitoring Devices",
              "Emergency Response Team",
              "K-9 Security Services",
              "VIP Protection Services",
            ],
          },
          {
            vendor: "Cleaning Services",
            services: [
              "Event Cleaning Services",
              "Waste Management Services",
              "Restroom Attendants",
              "Janitorial Services",
              "Deep Cleaning Services",
              "Sanitization Services",
              "Linen Cleaning Services",
              "Specialty Cleaning Services",
            ],
          },
        ],
      });
    }, 1000);
  });
};

const baseURL = "https://qp-api-4681075e5ca6.herokuapp.com";

export const getStates = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/locations`);
    return response.data.locations.data;
  } catch (error) {
    throw error;
  }
};

export const getBudgets = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/budgets`);
    return response.data.budgets.data;
  } catch (error) {
    throw error;
  }
};

export const getServices = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/services`);
    return response.data.services.data;
  } catch (error) {
    throw error;
  }
};

export const getEventTypes = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/events/types`);
    return response.data.event_types.data;
  } catch (error) {
    throw error;
  }
};

export const submitEvent = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/api/events/create`, data);
    return response.data;
  } catch (err) {}
};

export const newsLetterSignUp = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/api/newsletter`, data);
    console.log(response);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      if (Array.isArray(error.response.data.message)) {
        const errorMessage = error.response.data.message;
        errorMessage.forEach((mes) => toast.error(mes));
      } else {
        toast.error(error.response.data.message);
      }
    }
    console.log(error);
  }
};
