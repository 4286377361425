import React, { useRef, useState } from "react";
import axios from "axios";
import cloudComputing from "../../assets/cloud computing.svg";
import { Line } from "rc-progress";

function PortfolioForm({ setFormData, formData, imageErrors }) {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const imageRef = useRef();

  const handleFileValidation = (files) => {
    const validFiles = files.filter((file) => file.type.startsWith("image/"));

    if (selectedImages.length + validFiles.length > 20) {
      return false;
    } else if (validFiles.length !== files.length) {
      return false;
    }
    return validFiles;
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = handleFileValidation(files);

    if (validFiles && validFiles.length > 0) {
      setSelectedImages((prevImages) => [...prevImages, ...validFiles]);
      handleUpload(validFiles);
    }
  };

  const selectImage = () => {
    imageRef.current.click();
  };

  const handleDelete = async (index) => {
    setFormData((prevData) => ({
      ...prevData,
      images: prevData.images.filter((_, i) => i !== index),
    }));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const files = Array.from(event.dataTransfer.files);
    const validFiles = handleFileValidation(files);

    if (validFiles && validFiles.length > 0) {
      setSelectedImages((prevImages) => [...prevImages, ...validFiles]);
      handleUpload(validFiles);
    }
  };

  const handleUpload = async (imagesToUpload) => {
    setUploading(true);
    try {
      const uploadedUrls = await Promise.all(
        imagesToUpload.map(async (image) => {
          const formData = new FormData();
          formData.append("file", image);
          formData.append("upload_preset", "Quickplanners");

          const response = await axios.post(
            "https://api.cloudinary.com/v1_1/dcnxmsksm/image/upload",
            formData,
            {
              onUploadProgress: (data) => {
                setUploadProgress(Math.round((data.loaded / data.total) * 100));
              },
            }
          );

          return response.data.secure_url;
        })
      );

      setFormData((prevData) => ({
        ...prevData,
        images: [...(prevData.images || []), ...uploadedUrls],
      }));

      setUploadProgress(0);
      setUploading(false);

      setSelectedImages([]);
    } catch (err) {
      setUploading(false);
    }
  };

  return (
    <>
      <h2 className="main-head">Portfolio</h2>
      <p className="pf-text">
        Upload up to 20 photos showcasing your best work.
      </p>
      <p className="pf-text">
        Make sure your images are high-quality and clear enough for potential
        clients.
      </p>

      <div className="image-uploader">
        <div
          className={`upload-button ${isDragging ? "dragging" : ""}`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="d-flex flex-column align-items-center gap-4 justify-content-center w-100">
            {isDragging ? (
              <span className="select">Drop images here</span>
            ) : (
              <>
                <img src={cloudComputing} alt="cloud computing icon" />
                <span>
                  Drag file to upload or{" "}
                  <span className="browse" role="button" onClick={selectImage}>
                    Browse
                  </span>
                </span>
              </>
            )}
            <input
              type="file"
              multiple
              accept="image/jpeg,image/png,image/gif"
              ref={imageRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="file-upload"
            />
          </div>
        </div>
        {uploading && (
          <div className="mt-3">
            {" "}
            <Line
              percent={uploadProgress}
              strokeWidth={1}
              strokeColor="#115dd6"
            />
          </div>
        )}
        <div className="image-gallery">
          {formData?.images?.length > 0 && (
            <>
              {formData?.images.map((image, index) => (
                <div key={index} className="image-item">
                  <img
                    src={image}
                    alt={`Uploaded ${index}`}
                    style={{ aspectRatio: "1/1" }}
                  />
                  <div className="image-details">
                    <div className="delete-btn">
                      {" "}
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(index)}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PortfolioForm;
