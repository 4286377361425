import { useEffect } from "react";
import "./Toast.css";

export default function Toast({ toasts = [], removeToast }) {
  useEffect(() => {
    toasts.forEach((toast) => {
      setTimeout(() => {
        removeToast(toast.id);
      }, 1000);
    });
  }, [toasts, removeToast]);
  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-3 z-index-3">
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className="toast show"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <i className="ki-duotone ki-abstract-23 fs-2 text-success me-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <strong className="toast-header-title me-auto">
              {toast.header}
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => removeToast(toast.id)}
            ></button>
          </div>
          <div className="toast-body">{toast.body}</div>
        </div>
      ))}
    </div>
  );
}
